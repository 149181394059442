import React, { useState } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { Button, Menu, Row, Col } from 'antd'
import { useTranslation } from 'next-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../../../redux/authSlice'

import HomeTree from '../../../config/HomeTree'
import Logout from '../../svgs/sider/Logout'
import Register from '../../shared-components/modals/auth/Register'
import Login from '../../shared-components/modals/auth/Login'

export default function MenuContent({ setOpen }) {
  const dispatch = useDispatch()
  const router = useRouter()
  const { t } = useTranslation('HomeLayout')

  const { isAuth } = useSelector((state) => state.auth)

  const [registerOpen, setRegisterOpen] = useState(false)
  const [loginOpen, setLoginOpen] = useState(false)

  return (
    <>
      <Menu mode='inline'>
        {HomeTree.map((menu, index) => (
          <React.Fragment key={index}>
            {Array.isArray(menu.submenu) ? (
              <React.Fragment key={`submenu-${menu.key}`}>
                <Menu.SubMenu
                  className='!text-2xl'
                  key={menu.key}
                  title={t(menu.title)}
                >
                  {menu.submenu.map((subMenu) => {
                    return (
                      <Menu.Item
                        key={subMenu.key}
                        className={`text-2xl py-50 ${
                          router.asPath.split('/')[1] === subMenu.path
                            ? 'active'
                            : ''
                        }`}
                        onClick={() => setOpen(false)}
                      >
                        {t(subMenu.title)}
                        <Link href={`/${subMenu.path}`} />
                      </Menu.Item>
                    )
                  })}
                </Menu.SubMenu>
              </React.Fragment>
            ) : (
              <Menu.Item
                key={menu.key}
                className={`text-2xl py-50 ${
                  router.asPath.split('/')[1] === menu.path ? 'active' : ''
                }`}
                onClick={() => setOpen(false)}
              >
                {t(menu.title)}
                <Link href={`/${menu.path}`} />
              </Menu.Item>
            )}
          </React.Fragment>
        ))}

        <hr className='mt-28 mb-28 border-[#4D4D4D]' />

        {isAuth && (
          <Menu.Item
            key='logout'
            className='text-2xl py-40'
            icon={<Logout />}
            onClick={() => {
              dispatch(logout())
              router.push('/')
            }}
          >
            Logout
          </Menu.Item>
        )}
      </Menu>

      {!isAuth && (
        <div className='mx-28'>
          <Row gutter={8}>
            <Col span={12}>
              <Button
                className='btn-green h-auto w-full text-sm font-medium rounded-lg py-16 mr-20'
                onClick={() => setLoginOpen(true)}
              >
                {t('homenav.Login')}
              </Button>
            </Col>
            <Col span={12}>
              <Button
                className='btn-green-outlined h-auto w-full text-sm font-medium rounded-lg py-16'
                onClick={() => setRegisterOpen(true)}
              >
                {t('homenav.Register')}
              </Button>
            </Col>
          </Row>
        </div>
      )}

      <Register open={registerOpen} setOpen={setRegisterOpen} />
      <Login open={loginOpen} setOpen={setLoginOpen} />
    </>
  )
}
