import { Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'
import { WhatsAppOutlined } from '@ant-design/icons'

export default function ClickToChat() {
  const { t } = useTranslation('common')
  return (
    <Tooltip placement='left' title={t('Click to Chat')}>
      <a
        className='chat fixed right-24 bottom-24 z-40'
        rel='noreferrer'
        target='_blank'
        href='https://api.whatsapp.com/send?phone=393516116011'
      >
        <div className='bg-green w-72 h-72 flex items-center justify-center rounded-full'>
          <WhatsAppOutlined className='text-3xl text-white' />
        </div>
      </a>
    </Tooltip>
  )
}
