import Image from 'next/image'
import Link from 'next/link'
import { webAssets } from '../../../utils/webAssets'

const FooterLower = () => {
  return (
    <div className='bg-black text-white py-24'>
      <div className='container mx-auto flex flex-wrap items-center justify-center sm:justify-between gap-20'>
        <p className='text-sm mb-0'>
          copyright © 2010-2023 Smart Services Srl All rights reserved.
        </p>

        <div className='flex items-center gap-16'>
          <Link
            className='svg-white'
            rel='noreferrer'
            target='_blank'
            href='https://www.facebook.com/Smart-Caf-101772231520624/'
          >
            <Image
              alt='https://www.facebook.com/Smart-Caf-101772231520624/'
              src={webAssets.facebookFooter}
              width={32}
              height={32}
            />
          </Link>
          <Link
            className='svg-white'
            rel='noreferrer'
            target='_blank'
            href='https://www.instagram.com/smartcaf/?hl=it'
          >
            <Image
              alt='https://www.instagram.com/smartcaf/?hl=it'
              src={webAssets.instagramFooter}
              width={32}
              height={32}
            />
          </Link>
          <Link
            className='svg-white'
            rel='noreferrer'
            target='_blank'
            href='https://www.linkedin.com/company/smart-caf/'
          >
            <Image
              alt='https://www.linkedin.com/company/smart-caf/'
              src={webAssets.linkedinFooter}
              width={32}
              height={32}
            />
          </Link>
        </div>
      </div>
    </div>
  )
}

export default FooterLower
