import { Divider } from 'antd'
import { memo, useEffect, useState } from 'react'
import Cookies from 'js-cookie'
import { useDispatch } from 'react-redux'
import { profileData } from '../../../redux/authSlice'

import Navbar from './Navbar'
import FooterUpper from './FooterUpper'
import FooterLower from './FooterLower'
import Loading from '../../shared-components/Loading'
import ClickToChat from '../../shared-components/ClickToChat'

const Layout = ({ children }) => {
  const dispatch = useDispatch()
  const [isloaded, setIsLoaded] = useState(false)

  useEffect(() => {
    setIsLoaded(true)
  }, [])

  useEffect(() => {
    if (Cookies.get('SMARTCAF_USER_JWT_TOKEN')) {
      dispatch(profileData())
    }
  }, [dispatch])

  return (
    <>
      {!isloaded ? (
        <Loading classes='!h-screen' />
      ) : (
        <>
          <ClickToChat />
          <Navbar />
          <main className='overflow-x-hidden'>{children}</main>
          <FooterUpper />
          <Divider className='my-0' />
          <FooterLower />
        </>
      )}
    </>
  )
}

export default memo(Layout)
