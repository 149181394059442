import { useRouter } from 'next/router'
import Link from 'next/link'
import Image from 'next/image'
import { Row, Col } from 'antd'
import { useTranslation } from 'next-i18next'
import { webAssets } from '../../../utils/webAssets'

const gutter = [
  { xs: 8, sm: 16, md: 12, lg: 32, xl: 48 },
  { xs: 30, sm: 24, md: 24, lg: 32, xl: 48 },
]

const FooterUpper = () => {
  const { t } = useTranslation('HomeLayout')
  const router = useRouter()

  return (
    <footer className='bg-black text-white pt-60 pb-42'>
      <div className='container mx-auto'>
        <Image
          alt='smartcaf-logo'
          src={webAssets.logoDark}
          className='!w-[6.5rem] !h-[4.5rem] cursor-pointer mb-24'
          onClick={() => router.push('/')}
          width={96}
          height={67}
        />
        <Row justify='space-between' gutter={gutter}>
          <Col xs={24} lg={8}>
            <div>
              <p className='text-sm leading-6 mb-24'>
                {t(
                  'Siamo il primo CAF in Italia completamente Online. Il nostro servizio si basa sulla firma elettronica avanzata che ha assoluto valore legale.'
                )}
              </p>
              <div className='flex items-center gap-8 mb-24'>
                <p className='text-base font-medium mb-0'>
                  {t('footer.PoweredBy')}
                </p>
                <Image
                  alt='google'
                  src={webAssets.google}
                  width={16}
                  height={16}
                />
              </div>
              <div className='flex items-center gap-8'>
                <Image
                  alt='Via Pola 11, 20124 Milano'
                  src={webAssets.location}
                  width={14}
                  height={20}
                />
                <p className='text-base font-medium mb-0'>
                  {t('Via Pola 11, 20124 Milano')}
                </p>
              </div>
            </div>
          </Col>

          <Col xs={12} lg={4}>
            <h3 className='text-lg font-bold mb-24'>{t('Link veloci')}</h3>
            <ul className='text-base'>
              <li className='mb-20 last:mb-0'>
                <Link className='hover:text-green' href='/about-us'>
                  {t('footer.AboutUS')}
                </Link>
              </li>
              <li className='mb-20 last:mb-0'>
                <Link className='hover:text-green' href='/modulo-revova'>
                  {t('Modulo Revoca')}
                </Link>
              </li>
              <li className='mb-20 last:mb-0'>
                <Link className='hover:text-green' href='/blog'>
                  {t('Blog')}
                </Link>
              </li>
              <li className='mb-20 last:mb-0'>
                <Link className='hover:text-green' href='/contact-us'>
                  {t('footer.info.FAQ')}
                </Link>
              </li>
            </ul>
          </Col>

          <Col xs={12} lg={4}>
            <h4 className='text-lg font-bold mb-24'>
              {t('footer.info.title')}
            </h4>
            <ul className='text-base'>
              <li className='mb-20 last:mb-0'>
                <Link className='hover:text-green' href='/terms-and-conditions'>
                  {t('Terms and Conditions')}
                </Link>
              </li>
              <li className='mb-20 last:mb-0'>
                <Link className='hover:text-green' href='/privacy-and-policy'>
                  {t('footer.info.PrivacyPolicy')}
                </Link>
              </li>
              <li className='mb-20 last:mb-0'>
                <Link className='hover:text-green' href='/cookie-policy'>
                  {t('footer.info.CookiePolicy')}
                </Link>
              </li>
              <li className='mb-20 last:mb-0'>
                <Link
                  className='hover:text-green'
                  href='/manuale-operativo-FEA'
                >
                  {t('Manuale Operativo FEA')}
                </Link>
              </li>
            </ul>
          </Col>

          <Col xs={24} lg={6}>
            <h4 className='text-lg font-bold mb-24'>
              {t('footer.Consultation')}
            </h4>

            <h3 className='mb-20'>{t('footer.ContactUs.title')}</h3>

            <h4 className='text-[#657384] text-base font-medium mb-12'>
              {t('footer.ContactUs')}
            </h4>

            <div className='flex items-center gap-12'>
              <Link
                rel='noreferrer'
                target='_blank'
                href='https://api.whatsapp.com/send?phone=393516116011'
              >
                <Image
                  alt='https://api.whatsapp.com/send?phone=393516116011'
                  src={webAssets.whatsapp}
                  width={32}
                  height={32}
                />
              </Link>
              <Link
                rel='noreferrer'
                target='_blank'
                href='https://www.facebook.com/messages/t/101772231520624'
              >
                <Image
                  alt='https://www.facebook.com/messages/t/101772231520624'
                  src={webAssets.Messenger}
                  width={32}
                  height={32}
                />
              </Link>
            </div>
          </Col>
        </Row>
      </div>
    </footer>
  )
}

export default FooterUpper
