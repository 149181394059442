// const { default: Services } = require('@/components/svgs/sider/Services')
// const { default: ServicesA } = require('@/components/svgs/sider/ServicesA')
// const { default: Requests } = require('@/components/svgs/sider/Requests')
// const { default: RequestsA } = require('@/components/svgs/sider/RequestsA')
// const { default: FollowUp } = require('@/components/svgs/sider/FollowUp')
// const { default: FollowUpA } = require('@/components/svgs/sider/FollowUpA')

const HomeTree = [
  {
    key: 'home',
    path: '',
    title: 'Home',
    // icon: <Services />,
    // iconActive: <ServicesA />,
  },
  {
    key: 'services',
    path: 'services',
    title: 'homenav.Services',
    // icon: <Services />,
    // iconActive: <ServicesA />,
    submenu: [
      {
        key: 'Generale',
        path: 'services',
        title: 'CAF e patronato',
      },
      {
        key: 'Pacchetti',
        path: 'pacchetti',
        title: 'Pacchetti',
      },
    ],
  },
  {
    key: 'AboutUs',
    path: 'about-us',
    title: 'homenav.AboutUs',
    // icon: <Requests />,
    // iconActive: <RequestsA />,
  },
  {
    key: 'ContactUs',
    path: 'contact-us',
    title: 'homenav.ContactUs',
    // icon: <FollowUp />,
    // iconActive: <FollowUpA />,
  },
  {
    key: 'blog',
    path: 'blog',
    title: 'Blog',
    // icon: <FollowUp />,
    // iconActive: <FollowUpA />,
  },
]

export default HomeTree
