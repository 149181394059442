import Image from 'next/image'
import { Drawer } from 'antd'
import { useRouter } from 'next/router'
import { webAssets } from '../../../utils/webAssets'

import MenuContent from './MenuContent'

export default function SideNav({ open, setOpen }) {
  const router = useRouter()
  return (
    <>
      <Drawer
        className='sidenav-drawer'
        title={null}
        placement='left'
        width={293}
        onClose={() => setOpen(false)}
        open={open}
        closable={false}
      >
        <div className='text-center mb-32'>
          <Image
            alt='smartcaf-logo'
            width={86}
            height={60}
            src={webAssets.logoDark}
            preview={false}
            className='mx-auto cursor-pointer'
            onClick={() => {
              router.push('/')
              setOpen(false)
            }}
          />
        </div>

        <MenuContent setOpen={setOpen} />
      </Drawer>
    </>
  )
}
