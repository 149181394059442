import { useState, useEffect, useMemo, useRef } from 'react'
import { Dropdown, Button } from 'antd'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { clearError } from '../../../redux/authSlice'
import { setNavbarHeight } from '../../../redux/themeSlice'
import { webAssets } from '../../../utils/webAssets'

import NavNotification from '../NavNotification'
import NavProfile from '../NavProfile'
import NavLanguage from '../NavLanguage'
import Register from '../../shared-components/modals/auth/Register'
import Login from '../../shared-components/modals/auth/Login'
import NavToggler from '../../svgs/NavToggler'
import SideNav from './SideNav'
import Chevron from '../../svgs/Chevron'

const HomeNav = () => {
  const ref = useRef(null)
  const router = useRouter()
  const dispatch = useDispatch()
  const { t } = useTranslation('HomeLayout')

  const coversation_token = useMemo(
    () => router.query.coversation_token,
    [router.query.coversation_token]
  )

  const { isAuth } = useSelector((state) => state.auth)

  const [sideNavOpen, setSideNavOpen] = useState(false)
  const [registerOpen, setRegisterOpen] = useState(false)
  const [loginOpen, setLoginOpen] = useState(false)

  // HANDLE IF COVERSATION TOKEN EXIST [TICKETS]
  useEffect(() => {
    if (coversation_token && !registerOpen) {
      localStorage.setItem('SMARTCAF_conversation_token', coversation_token)
      setRegisterOpen(true)
    }
  }, [setRegisterOpen, registerOpen, coversation_token])

  // HANDLE IF REDIRECTION LINK EXIST
  useEffect(() => {
    if (router.query['redirection_link']) {
      setLoginOpen(true)
    }
  }, [router.query])

  // SETTING NAVBAR HEIGHT
  useEffect(() => {
    dispatch(setNavbarHeight(ref.current.clientHeight))
  })

  const navLinks = [
    {
      id: 0,
      title: 'Home',
      pathname: '',
    },
    {
      id: 1,
      title: 'homenav.Services',
      pathname: 'services',
    },
    {
      id: 2,
      title: 'homenav.AboutUs',
      pathname: 'about-us',
    },
    {
      id: 3,
      title: 'homenav.ContactUs',
      pathname: 'contact-us',
    },
    {
      id: 4,
      title: 'Blog',
      pathname: 'blog',
    },
  ]

  const packagesItems = [
    // {
    //   key: '1',
    //   label: (
    //     <div
    //       className='flex items-center gap-8'
    //       onClick={() => router.push('/pacchetti/mamme')}
    //     >
    //       <PersonPregnant style='w-20 h-20' />
    //       <span className='hover:text-black font-base font-semibold capitalize'>
    //         Mamme
    //       </span>
    //     </div>
    //   ),
    // },
    // {
    //   key: '2',
    //   label: (
    //     <span className='hover:text-black font-base font-semibold capitalize'>
    //       Colf e Badanti
    //     </span>
    //   ),
    // },
  ]

  const CAFItems = [
    {
      key: '1',
      label: (
        <div
          className='hover:text-black font-base font-semibold'
          onClick={() => router.push('/services')}
        >
          Tutti i servizi
        </div>
      ),
    },
    {
      key: '2',
      label: (
        <div
          className='hover:text-black font-base font-semibold capitalize'
          onClick={() => router.push('/pacchetti')}
        >
          Pacchetti
        </div>
      ),
      // label: (
      //   <Dropdown
      //     placement='right'
      //     menu={{
      //       items: packagesItems,
      //     }}
      //   >
      //     <div
      //       className='flex items-center gap-8'
      //       onClick={() => router.push('/pacchetti')}
      //     >
      //       <span className='hover:text-black font-base font-semibold capitalize'>
      //         Pacchetti
      //       </span>
      //       <Chevron style='-rotate-90' />
      //     </div>
      //   </Dropdown>
      // ),
    },
  ]

  const servicesItems = [
    {
      key: '1',
      label: (
        <Dropdown
          placement='rightTop'
          menu={{
            items: CAFItems,
          }}
        >
          <div
            className='flex items-center gap-8'
            onClick={() => router.push('/services')}
          >
            <span className='hover:text-black font-base font-semibold'>
              CAF e Patronato
            </span>
            <Chevron style='-rotate-90' />
          </div>
        </Dropdown>
      ),
    },
    // {
    //   key: '2',
    //   label: (
    //     <span className='hover:text-black font-base font-semibold capitalize'>
    //       Assicurazione
    //     </span>
    //   ),
    // },
    {
      key: '3',
      label: (
        <div
          className='hover:text-black font-base font-semibold capitalize'
          onClick={() => router.push('/dashboard/utenze-casa')}
        >
          Luce e Gas
        </div>
      ),
    },
    // {
    //   key: '4',
    //   label: (
    //     <span className='hover:text-black font-base font-semibold capitalize'>
    //       Traduzione
    //     </span>
    //   ),
    // },
  ]

  return (
    <>
      <nav
        ref={ref}
        className='bg-white shadow-sm py-20 sticky top-0 right-0 left-0 z-99'
      >
        <div className='container flex justify-between items-center mx-auto'>
          {/* LEFT */}
          <div className='flex items-center'>
            {/* LOGO */}
            <Image
              src={webAssets.logoLight}
              alt='logo'
              width={86}
              height={60}
              className='ltr:lg:mr-50 rtl:lg:ml-50 !w-[5.563rem] !h-[3.875rem] cursor-pointer'
              onClick={() => router.push('/', undefined, { shallow: true })}
            />

            {/* NAVLINKS */}
            <ul className='hidden lg:flex items-center mb-0'>
              {navLinks.map((ele) => {
                if (ele.title === 'homenav.Services') {
                  return (
                    <Dropdown
                      key={ele.id}
                      menu={{
                        items: servicesItems,
                      }}
                    >
                      <li
                        className={`text-[#AAA] ltr:mr-50 ltr:last:mr-0 rtl:ml-50 rtl:last:ml-0 text-base font-medium hover:text-green transition-colors relative ${
                          router.asPath.split('/')[1] === ele.pathname
                            ? 'text-green'
                            : ''
                        } flex items-center gap-8`}
                      >
                        <Link href={`/${ele.pathname}`}>{t(ele.title)}</Link>
                        <Chevron color='#AAA' />
                      </li>
                    </Dropdown>
                  )
                } else
                  return (
                    <li
                      key={ele.id}
                      className={`text-[#AAA] ltr:mr-50 ltr:last:mr-0 rtl:ml-50 rtl:last:ml-0 text-base font-medium hover:text-green transition-colors relative ${
                        router.asPath.split('/')[1] === ele.pathname
                          ? 'text-green'
                          : ''
                      }`}
                    >
                      <Link href={`/${ele.pathname}`}>{t(ele.title)}</Link>
                    </li>
                  )
              })}
            </ul>
          </div>

          {/* RIGHT */}
          <div className='flex items-center'>
            <NavLanguage />

            {isAuth ? (
              <>
                <NavNotification />
                <NavProfile />
              </>
            ) : (
              <div className='ltr:ml-24 rtl:mr-24'>
                <Button
                  className='hidden lg:inline btn-green h-auto w-128 text-sm font-medium rounded-lg py-16 ltr:mr-20 rtl:ml-20'
                  onClick={() => {
                    dispatch(clearError())
                    setLoginOpen(true)
                  }}
                >
                  {t('homenav.Login')}
                </Button>
                <Button
                  className='hidden lg:inline btn-green-outlined h-auto w-128 text-sm font-medium rounded-lg py-16'
                  onClick={() => setRegisterOpen(true)}
                >
                  {t('homenav.Register')}
                </Button>
              </div>
            )}

            <div className='flex items-center gap-12 ltr:ml-20 rtl:mr-20'>
              <Image
                alt='contact'
                src={webAssets.callCalling}
                width={24}
                height={24}
                className='hidden sm:inline'
              />
              <div className='text-center'>
                <p className='text-green text-sm mb-0'>Serve assistenza?</p>
                <a
                  className='text-base font-bold hover:text-black'
                  href='tel:0291443332'
                >
                  02 91443332
                </a>
              </div>
            </div>

            {/* NAV TOGGLER */}
            <NavToggler
              style='lg:hidden ltr:ml-20 rtl:mr-20 ltr:-scale-x-100 cursor-pointer'
              onClick={() => setSideNavOpen(true)}
            />
          </div>
        </div>
      </nav>

      <Register open={registerOpen} setOpen={setRegisterOpen} />
      <Login open={loginOpen} setOpen={setLoginOpen} />
      <SideNav open={sideNavOpen} setOpen={setSideNavOpen} />
    </>
  )
}

export default HomeNav
